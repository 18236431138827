<template>
	<main id="container" class="page-container bg-wave1 page-festival">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="지역행사" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제와 함께 하는</span> 전국 행사를 만나보세요.</h3>
				</div>
				<div class="map-wrap">
					<div class="flex-l">
						<div class="map-area">
							<!-- <button v-if="item.count > 0" type="button" class="map-pin" :class="{ 'is-active': item.active }" :style="`left: ${item.x}px; top:${item.y}px`" v-for="(item, _) in pinList" @click="onClickMap(item)">
								<span class="nums">{{ item.count }}</span>
								<i class="icon">
									<icon v-if="item.active" name="icon-mappin-on" />
									<icon v-else name="icon-mappin" />
								</i>
							</button> -->

							<button v-if="item.count > 0" type="button" class="map-pin" :class="{ 'is-active': item.active }" :style="`left: ${item.x}px; top:${item.y}px`" v-for="(item, _) in pinList" @click="onClickMap(item)"></button>
						</div>
					</div>
					<div class="flex-r" :class="{ 'is-active': visibleInfo }">
						<!-- 지도 클릭시 정보 -->
						<map-info 
							@closeModal="handleModal" 
							:visible="visibleInfo" 
							:arDcdOptions="arDcdOptions" 
							:localEventList="localEventList" 
							:callAutoPlay="callAutoPlay" 
						/>
					</div>
				</div>
				<div class="festival-search">
					<div class="flex-l">
						<div class="dropdown-style">
							<DropDown ref="dropDown1" id="dropDown1" placeholder="지역 선택">
								<div class="dropdown-item" v-for="(dcd, idx) in arOptions" :key="idx" :data-value="dcd.dcd">
									<button type="button" @click="onClickArDropDn($event, dcd.dcd)" class="text-link">
										<span class="dropdown-text">{{ dcd.dcdVal }}</span>
									</button>
								</div>
							</DropDown>
						</div>
					</div>
					<div class="flex-r">
						<div class="category-search-area">
							<!-- <input type="text" class="category-search-input" v-model="prtcCoSrchFilter.prtcCoNm" placeholder="행사명을 입력하세요." @keyup.enter="onKeyUpSrch" /> -->
							<input type="text" class="category-search-input" v-model="arEventSrchFilter.arEventNm" placeholder="행사명을 입력하세요." @keyup.enter="onKeyUpSrch" />
							<button class="category-search-button" @click="onClickSrch">
								<i class="icon">
									<img src="@/assets/img/category/search_icon.svg" alt="검색" />
								</i>
							</button>
						</div>
					</div>
				</div>
				<section class="festival-table">
					<h3 class="festival-table-title">행사목록</h3>
					<div class="table">
						<table class="table is-bordered">
							<caption>
								번호, 행사명, 지역, 기간, 소개 항목의 행사 목록
							</caption>
							<colgroup>
								<col style="width: 60px" />
								<col style="width: 20%" />
								<col style="width: 10%" />
								<col style="width: 30%" />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th scope="col">번호</th>
									<th scope="col">행사명</th>
									<th scope="col">지역</th>
									<th scope="col">기간</th>
									<th scope="col">소개</th>
								</tr>
							</thead>
							<!-- <tbody v-if="prtcCoList.length > 0">
								<tr v-for="(item, idx) in prtcCoList" :key="idx">
									<td>{{ idx + 1 }}</td>
									<td>{{ item.prtcCoNm }}</td>
									<td>{{ item.arNm }}</td>
									<td>{{ item.prtcFldNm }}</td>
									<td class="text-left">{{ item.coIntro }}</td>
								</tr>
							</tbody> -->
							<tbody v-if="arEventTabularList.length > 0">
								<tr v-for="(item, idx) in arEventTabularList" :key="idx">
									<td>{{ idx + 1 }}</td>
									<td>{{ item.arEventNm }}</td>
									<td>{{ item.arNm }}</td>
									<td>{{ item.date }}</td>
									<td class="text-left">{{ item.eventIntro }}</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="5" class="data-none">
										<span>등록된 데이터가 없습니다.</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</div>
		<div class="pagination-wrap">
			<!-- <portal-pagination 
				page-name="Festival" 
				:total-count="pagingState.totalCount" 
				:page-size="pagingState.pageSize" 
				:page-no="pagingState.pageNo" 
				:query="queries" 
				:parent-method-name="'getPrtcCoList'" 
				@getPrtcCoList="getPrtcCoList"
			></portal-pagination> -->
			<portal-pagination 
				page-name="Festival" 
				:total-count="pagingState.totalCount" 
				:page-size="pagingState.pageSize" 
				:page-no="pagingState.pageNo" 
				:query="queries" 
				:parent-method-name="'getArEventTabularList'" 
				@getArEventTabularList="getArEventTabularList"
			></portal-pagination>
		</div>
	</main>
</template>

<script>
import Icon from '@/components/common/Icon';
import PageHeader from '@/components/content/PageHeader';
import DropDown from '@/components/common/DropDown';
import PortalPagination from '@/components/PortalPaginationRoute';
import MapInfo from './MapInfo';

import { mapGetters } from 'vuex';
import { ACT_GET_COMMON_CODE_LIST, ACT_GET_AR_EVENT_LIST, ACT_GET_PRTC_CO_LIST } from '@/store/_act_consts';
import { MUT_SET_COMMON_CODE_ITEMS } from '@/store/_mut_consts';

import { lengthCheck, getItems, getCheckItems, setPaging } from '@/assets/js/utils';

export default {
	name: 'Festival',
	components: { PageHeader, DropDown, Icon, PortalPagination, MapInfo },
	data: () => ({
		visibleInfo: false,

		callAutoPlay: true,
		arDcdOptions: [],
		pinList: [
			{
				x: 316,
				y: 179,
				label: '강원도',
				dcd: 1504001,
			},
			{
				x: 189,
				y: 242,
				label: '경기도',
				dcd: 1504002,
			},
			{
				x: 305,
				y: 532,
				label: '경상남도',
				dcd: 1504003,
			},
			{
				x: 365,
				y: 364,
				label: '경상북도',
				dcd: 1504004,
			},
			{
				x: 141,
				y: 566,
				label: '광주',
				dcd: 1504005,
			},
			{
				x: 356,
				y: 460,
				label: '대구',
				dcd: 1504006,
			},
			{
				x: 202,
				y: 383,
				label: '대전',
				dcd: 1504007,
			},
			{
				x: 419,
				y: 557,
				label: '부산',
				dcd: 1504008,
			},
			{
				x: 157,
				y: 194,
				label: '서울',
				dcd: 1504009,
			},
			{
				x: 181,
				y: 344,
				label: '세종',
				dcd: 1504010,
			},
			{
				x: 440,
				y: 506,
				label: '울산',
				dcd: 1504011,
			},
			{
				x: 114,
				y: 197,
				label: '인천',
				dcd: 1504012,
			},
			{
				x: 194,
				y: 573,
				label: '전라남도',
				dcd: 1504013,
			},
			{
				x: 143,
				y: 451,
				label: '전라북도',
				dcd: 1504014,
			},
			{
				x: 100,
				y: 737,
				label: '제주도',
				dcd: 1504015,
			},
			{
				x: 131,
				y: 345,
				label: '충청남도',
				dcd: 1504016,
			},
			{
				x: 242,
				y: 308,
				label: '충청북도',
				dcd: 1504017,
			},
		],
		arEventList: [],
		localEventList: [],

		arEventSrchFilter: {
			arDcd: null,
			arEventNm: '',
		},
		arEventTabularList: [],

		// prtcFldOptions: [],
		// prtcCoSrchFilter: {
		// 	arDcd: null,
		// 	prtcCoNm: '',
		// },
		// prtcCoList: [],
		pagingState: {
			hasMore: false,
			totalCount: 0,
			pageSize: 10,
			pageNo: 1,
		},
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('festival', ['PAGING']),

		campnId() {
			return this.campn.campnId;
		},
		arOptions() {
			let options = [...this.arDcdOptions];
			options.splice(0, 1);
			options.unshift({ dcd: null, dcdVal: '전체' });
			return options;
		},

		queries() {
			let result = {};
			// result.prtcCoNm = this.prtcCoSrchFilter.prtcCoNm;
			// result.arDcd = this.prtcCoSrchFilter.arDcd;
			result.arEventNm = this.arEventSrchFilter.arEventNm;
			result.arDcd = this.arEventSrchFilter.arDcd;

			return result;
		},
	},
	watch: {
		arDcdOptions(newValue) {
			if (newValue.length > 0) this.getArEventList();
		},
		// prtcFldOptions(newValue) {
		// 	if (newValue.length > 0) this.getPrtcCoList();
		// },
	},
	async created() {
		await this.getCommonCdList();
		this.initializePinList();
	},
	mounted() { },
	methods: {
		// event ======================================================================
		onClickMap(pin) {
			this.setPinActive(pin.dcd);
			this.handleAutoPlay();
		},
		handleModal(val) {
			this.visibleInfo = val;
		},

		onClickArDropDn(e, arDcd) {
			this.$refs.dropDown1.selectDropdownItem(e);
			// this.prtcCoSrchFilter.arDcd = arDcd;
			// this.getPrtcCoList();

			this.arEventSrchFilter.arDcd = arDcd;
			this.getArEventTabularList();
		},
		onKeyUpSrch() {
			// this.getPrtcCoList();
			this.getArEventTabularList();
		},
		onClickSrch() {
			// this.getPrtcCoList();
			this.getArEventTabularList();
		},

		// custom fn ==================================================================
		initializePinList() {
			this.pinList = this.pinList.map((pin) => ({
				...pin,
				// label: dcd.dcdVal,
				count: 0,
				active: false,
				localEventList: [],
			}));
		},
		setPinActive(dcd) {
			let activeDcd = dcd;
			const activeEventList = this.pinList.filter((x) => x.count > 0);

			if (activeEventList.length > 0) {
				if (activeDcd == null) {
					activeDcd = activeEventList[Math.floor(Math.random() * activeEventList.length)].dcd;
				}

				this.pinList.forEach((item, _) => {
					if (activeDcd == item.dcd) {
						item.active = true;
						this.localEventList = [...item.localEventList];
						
						this.triggerClickArDropDn(activeDcd);
					} else {
						item.active = false;
					}
				});
				this.visibleInfo = true;
			} else {
				this.localEventList = [];
				this.visibleInfo = false;
			}
		},
		triggerClickArDropDn(activeDcd = "") {
			this.arEventSrchFilter.arEventNm = "";
			this.arEventSrchFilter.arDcd = activeDcd;
			this.$refs.dropDown1.setDropdownItemByValue(activeDcd);
			this.getArEventTabularList(1);
		},

		removeHhmmssFromYmd(ymd) {
			return ymd.substring(0, 10);
		},
		getDayNm(ymd) {
			return new Date(ymd).toLocaleDateString('ko-KR', { weekday: 'long' }).substring(0, 1);
		},

		handleAutoPlay() {
			this.callAutoPlay = true;
		},

		// promise ====================================================================
		async getCommonCdList() {
			const getArDcdOptions = () => {
				this.arDcdOptions = this.commonCode.filter((x) => x.dbColumn === 'AR_DCD').map((y) => ({ dcd: y.dcd, dcdVal: y.dcdVal }));
				// this.getPrtcCoList();
				this.getArEventTabularList();
			};
			// const getPrtcFldOptions = () => {
			// 	this.prtcFldOptions = this.commonCode.filter((x) => x.dbColumn === 'PRTC_FLD_DCD').map((y) => ({ dcd: y.dcd, dcdVal: y.dcdVal }));
			// };

			if (this.commonCode.length === 0) {
				this.$store
					.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {})
					.then((res) => {
						this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
					})
					.then(() => {
						getArDcdOptions();
						// getPrtcFldOptions();
					});
			} else {
				getArDcdOptions();
				// getPrtcFldOptions();
			}
		},

		async getArEventList() {
			const modelAttr = {
				campnId: this.campnId,
				arDcd: null,
				pageSize: 2 ** (31 - 1),
			};

			this.$store
				.dispatch(`festival/${ACT_GET_AR_EVENT_LIST}`, modelAttr)
				.then((res) => {
					// console.log(res);
					if (lengthCheck(res)) {
						this.arEventList = getItems(res).map((item) => {
							const bgngYmd = this.removeHhmmssFromYmd(item.eventBgngYmd);
							const bgngDayNm = this.getDayNm(bgngYmd);
							const endYmd = this.removeHhmmssFromYmd(item.eventEndYmd);
							const endDayNm = this.getDayNm(endYmd);

							const arNm = this.arDcdOptions.find((dcd) => dcd.dcd === item.arDcd)?.dcdVal || '-';

							return {
								...item,
								arNm: arNm,
								date: `${bgngYmd} (${bgngDayNm}) ~ ${endYmd} (${endDayNm})`,
							};
						});

						this.pinList.forEach((x, _) => {
							for (let event of this.arEventList) {
								if (x.dcd == event.arDcd) {
									x.localEventList.push({ ...event });
								}
							}
						});
						this.pinList = this.pinList.map((y) => ({ ...y, count: y.localEventList.length }));
					} else {
						this.arEventList = [];
					}
				})
				.then(() => this.setPinActive())
				.catch(console.error);
		},

		async getArEventTabularList(pageNum = this.pagingState.pageNo) {
			const modelAttr = {
				campnId: this.campnId,
				arEventNm: this.arEventSrchFilter.arEventNm,
				arDcd: this.arEventSrchFilter.arDcd,
				...this.pagingState,
				pageNo: pageNum,
			};

			this.$store
				.dispatch(`festival/${ACT_GET_AR_EVENT_LIST}`, modelAttr)
				.then((res) => {
					// console.log(res);
					if (lengthCheck(res)) {
						this.arEventTabularList = getItems(res).map((item) => {
							const bgngYmd = this.removeHhmmssFromYmd(item.eventBgngYmd);
							const bgngDayNm = this.getDayNm(bgngYmd);
							const endYmd = this.removeHhmmssFromYmd(item.eventEndYmd);
							const endDayNm = this.getDayNm(endYmd);

							const arNm = this.arDcdOptions.find((dcd) => dcd.dcd === item.arDcd)?.dcdVal || '-';

							return {
								...item,
								arNm: arNm,
								date: `${bgngYmd} (${bgngDayNm}) ~ ${endYmd} (${endDayNm})`,
							};
						});
						setPaging(this.pagingState, res);
					} else {
						this.arEventTabularList = [];
						this.pagingState = this.PAGING;
					}
				})
				.catch(console.error);
		},

		// async getPrtcCoList(pageNum = this.pagingState.pageNo) {
		// 	const modelAttr = {
		// 		campnId: this.campnId,
		// 		prtcCoNm: this.prtcCoSrchFilter.prtcCoNm,
		// 		arDcd: this.prtcCoSrchFilter.arDcd,
		// 		...this.pagingState,
		// 		pageNo: pageNum,
		// 	};

		// 	this.$store
		// 		.dispatch(`festival/${ACT_GET_PRTC_CO_LIST}`, modelAttr)
		// 		.then((res) => {
		// 			// console.log(res);
		// 			if (lengthCheck(res)) {
		// 				this.prtcCoList = getItems(res).map((item) => {
		// 					let arNm = this.arDcdOptions.find((dcd) => dcd.dcd == item.arDcd)?.dcdVal || '-';
		// 					if (!!item.sggNm) arNm = `${arNm} (${item.sggNm})`;

		// 					return {
		// 						...item,
		// 						arNm: arNm,
		// 						prtcFldNm: this.prtcFldOptions.find((dcd) => dcd.dcd == item.prtcFldDcd)?.dcdVal,
		// 					};
		// 				});
		// 				setPaging(this.pagingState, res);
		// 			} else {
		// 				this.prtcCoList = [];
		// 				this.pagingState = this.PAGING;
		// 			}
		// 		})
		// 		.catch(console.error);
		// },
	},
};
</script>
