<template>
	<div class="map-info" :class="{ 'is-active': visible }">
		<div class="map-info-header">
			<button type="button" class="btn-close" @click="onClickClose()">
				<i class="icon-close">팝업닫기</i>
			</button>
		</div>
		<div class="swiper-festival-container">
			<swiper ref="swiper" class="swiper" :options="swiperOption" @slideChange="onSwiperChange">
				<swiper-slide v-for="(item, idx) in computedLocalEventList" :key="idx">
					<div class="festival-item">
						<strong class="festival-item-area">
							{{ item.arNm }}
						</strong>
						<span class="festival-item-img">
							<img :src="item.imgFileUrl" :alt="item.arEventNm" />
						</span>
						<div class="festival-item-text">
							<strong class="festival-item-title text-ellipsis-2">{{ item.arEventNm }}</strong>
							<dl class="festival-item-dl">
								<dt>기간</dt>
								<dd class="text-ellipsis-2">{{ item.date }}</dd>
							</dl>
							<dl class="festival-item-dl">
								<dt>장소</dt>
								<dd class="text-ellipsis-2">{{ item.plcInfo }}</dd>
							</dl>
							<dl class="festival-item-dl">
								<dt>소개</dt>
								<dd class="text-scroll">{{ item.eventIntro }}</dd>
							</dl>
						</div>
						<div class="festival-item-footer">
							<!-- :href="item.rltdUrl"  -->
							<a v-if="!!item.rltdUrl" 
								target="_blank" class="button-arrow-r" title="새창열림"
								@click="onClickMoreInfo($event, item)"
							>
								더보기
								<i class="icon"><icon name="icon-arrow-r" /></i>
							</a>
						</div>
					</div>
				</swiper-slide>
			</swiper>
			<div class="festival-swiper-controls">
				<swiper-controls :swiper="thisSwiper" :auto="true"></swiper-controls>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@/components/common/Icon';
import SwiperControls from '@/components/common/SwiperControls';

import { mapGetters } from 'vuex';
import { ACT_UPDATE_AR_EVENT_INQ_TMS } from '@/store/_act_consts';

export default {
	name: 'MapInfo',
	components: { Icon, SwiperControls },
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		arDcdOptions: {
			type: Array,
			default: [],
		},
		localEventList: {
			type: Array,
			default: [],
		},
		callAutoPlay: { 
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		thisSwiper: null,
		activeIndex: 0,
		swiperOption: {
			loop: false,
			// notNextTick: true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			// loopedSlides: 0,
			spaceBetween: 40,
			touchRatio: 0.2,
			navigation: {
				nextEl: '.swiper-festival-container .swiper-button-next',
				prevEl: '.swiper-festival-container .swiper-button-prev',
			},
			pagination: {
				el: '.swiper-festival-container  .swiper-pagination',
				type: 'fraction',
			},
		},
	}),
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
		...mapGetters('festival', []),

		campnId() {
			return this.campn.campnId;
		},
		computedLocalEventList() {
			return this.localEventList
		}
	},
	watch: {
		computedLocalEventList(newValue) {
			const swiper = this.$refs.swiper.$swiper;
			this.$nextTick(() => {
				swiper.slideTo(0);
				setTimeout(() => {
					swiper.update();
					swiper.autoplay.stop();
				}, 100);
			});

			
			this.$nextTick(() => {
				setTimeout(() => {
					swiper.autoplay.start();
				}, 200);
			});
		},
	},
	created() { },
	mounted() {
		this.$nextTick(() => {
			this.thisSwiper = this.$refs.swiper.$swiper;
		});
	},
	methods: {

		// event ======================================================================
		onSwiperChange() {
			this.activeIndex = this.thisSwiper.realIndex;
		},
		// gotoSwiper(idx) {
		// 	this.thisSwiper.slideTo(idx);
		// },
		onClickClose() {
			this.$emit('closeModal', false);
		},
		
		onClickMoreInfo(e, arevent) {
			const prefix = 'https://';
			let url = arevent.rltdUrl
			
			if (!url.startsWith(prefix)) {
				url = prefix + url;
			}
			window.open(url);
			
			this.updateArEventInqTms(arevent.arEventId)
		},

		// custom fn ==================================================================

		// promise ====================================================================
		async updateArEventInqTms(arEventId) {
			try {
				const params = {
					arEventId: arEventId, 
				}
				await this.$store.dispatch(`festival/${ACT_UPDATE_AR_EVENT_INQ_TMS}`, params)
			} catch (err) {
				console.error(err)
			}
        },
	},
};
</script>
